import React from 'react'
import Navbar2 from '../component/Navbar/navbar2';
import Footer2 from '../component/Footer/Footer2';
export default function TermsCondition() {
  return (
    <>
      <Navbar2 />
      <div className="TermsCondition">
        <div className="container">
          <div className="row">
            <h2>Terms and Conditions</h2>
            <p>BORROWBUDDY E-SERVICE PRIVATE LIMITED
            </p>
            <p>M-3, Pratap Bhawan, Bahadur Shah Zafar Marg, Indraprastha Estate, New
              Delhi, Central Delhi, Delhi, 110002</p>
            <h3>Introduction</h3>
            <p>Welcome to Borrowbuddy! These terms and conditions ("Terms &
              Conditions") outline the rules and regulations governing the use of our
              mobile application ("Borrowbuddy App ") and website at <a href="https://borrowbuddy.co.in">https://borrowbuddy.co.in</a>&nbsp;
              ("Website"), collectively referred to as the
              "Platform." By accessing and using our Platform, you agree to comply with
              these Terms & Conditions. If you disagree with any part of these terms,
              please refrain from using the Platform.
            </p>
            <h3>1. General</h3>
            <p>Borrowbuddy, the owner of the Platform, facilitates connections between
              consumers, financial institutions, data partners, and other partners for
              lending, borrowing, and other financial activities. The term "Users"
              encompasses consumers, financial institutions, regulated entities, data
              partners, and other partners. Your use of the Platform implies your
              acceptance of these Terms & Conditions.
            </p>
            <h3>2. Eligibility</h3>
            <p>To use the Platform, you must be at least 20 years old and legally allowed
              to enter into a contract in India. By registering on the Platform, you agree
              to abide by these Terms & Conditions. Borrowbuddy may terminate the
              membership of users violating these terms.
            </p>
            <h3>3. Acceptance of Terms & Conditions</h3>
            <p>As a user, you agree to the Terms & Conditions outlined on the Platform.
              Borrowbuddy reserves the right to modify these terms at its discretion
              without prior notice. Continued use of the Platform constitutes acceptance
              of any revisions</p>
            <h3>4. Your Account</h3>
            <p>To use the Platform, you must maintain an account, providing necessary
              information. You are responsible for the confidentiality of your account and
              must inform us promptly of any unauthorised access. The information you
              provide must be accurate, and any changes should be promptly
              communicated.</p>
            <h3>5. Privacy</h3>
            <p>Borrowbuddy collects user information to provide services, governed by
              our Privacy Policy.</p>
            <h3>6. License and Access</h3>
            <p>Borrowbuddy grants users a limited licence to access and use the Platform.
              Unauthorised access or modification is prohibited.
            </p>
            <h3>7. Monitoring of the Platform and Your Account</h3>
            <p>Borrowbuddy monitors Platform content for compliance with Terms &
              Conditions. Borrowbuddy may remove objectionable content and share
              information with authorities if necessary</p>
            <h3>8. Service Suspension</h3>
            <p>Borrowbuddy may suspend services without notice and will not be liable
              for any resulting damages.
            </p>
            <h3>9. Prohibited Uses</h3>
            <p>Users are prohibited from using the Platform for unlawful, abusive, or
              malicious purposes.
            </p>
            <h3>10. Limitation of Liability and Disclaimer of Warranties</h3>
            <p>Borrowbuddy disclaims liability for indirect, incidental, or consequential
              damages. Users accept the Platform "as is," and Borrowbuddy does not
              guarantee uninterrupted operation.</p>
            <h3>11. Indemnity</h3>
            <p>Users agree to indemnify Borrowbuddy from claims arising from their use
              of the Platform.</p>
            <h3>12. Advertisers/Third-Party Links</h3>
            <p>Borrowbuddy is not responsible for advertisements or third-party content
              linked on the Platform.
            </p>
            <h3>13. User Comments, Feedback, and Other Submissions</h3>
            <p>User submissions may be used by Borrowbuddy without compensation.
              Users must ensure submitted content adheres to legal standards.
            </p>
            <h3>14. Acknowledgments</h3>
            <p>Users acknowledge that Borrowbuddy is a platform connecting users, not
              engaged in lending or borrowing. Users are responsible for verifying
              information and handling financial transactions</p>
            <h3>15. Refund for Chargeback Transaction</h3>
            <p>Users must contact Borrowbuddy for refund claims related to chargeback
              transactions.
            </p>
            <h3>16. Refund for Fraudulent/Duplicate Transactions
            </h3>
            <p>Users must contact Borrowbuddy for issues related to fraudulent/duplicate
              transactions.
            </p>
            <h3>17. Applicable Laws
            </h3>
            <p>Use of the Platform and these Terms & Conditions are subject to Indian
              laws, with New Delhi courts having exclusive jurisdiction.</p>
            <h3>18. Grievances</h3>
            <p>Users can file complaints or provide feedback to the Grievance Officer at <a href="mailto::support@borrowbuddy.co.in">support@borrowbuddy.co.in</a>
              &nbsp;(mail to: support@borrowbuddy.co.in) or by
              calling +91 1166668018.</p>

          </div>
        </div>
      </div>
      <Footer2 />
    </>
  )
}
