import React from 'react'
import Navbar2 from '../component/Navbar/navbar2';
import Footer2 from '../component/Footer/Footer2';

export default function CancellationPolicy() {
    return (
        <>
            <Navbar2 />
            <div className="CancellationPolicy mb-5">
                <div className="container">
                    <div className="row">
                        <h2>Cancellation & Refund Policy</h2>
                        <p><strong>SUPERLINE FININVEST PRIVATE LIMITED</strong> is committed to providing
                            comprehensive support to its customers and maintains a flexible
                            cancellation policy. The key points under this policy are as follows:
                        </p>
                        <p className='mt-3'><strong>REFUND FOR CHARGEBACK TRANSACTION</strong></p>
                        <ul>
                            <li>In the event of a chargeback claim by the User, the User is required
                                to promptly approach SUPERLINE FININVEST PRIVATE LIMITED with
                                the claim details to request a refund. Any refund, if applicable, will be
                                processed exclusively by SUPERLINE FININVEST PRIVATE LIMITED
                                through a payment gateway, demand draft, or any other method
                                deemed appropriate by SUPERLINE FININVEST PRIVATE LIMITED .</li>
                                <br />
                            <li>Users are strictly prohibited from making refund or chargeback
                                claims directly to the Payment Service Provider(s)</li>
                        </ul>

                        <p className='mt-3'><strong>REFUND FOR FRAUDULENT/DUPLICATE TRANSACTION(s)</strong></p>

                        <p>For any fraudulent transaction(s) resulting from the misuse of Card/Bank
                            details by unauthorised individuals or parties, the User should directly
                            contact SUPERLINE FININVEST PRIVATE LIMITED. SUPERLINE FININVEST
                            PRIVATE LIMITED will address such issues in accordance with their
                            established policies and rules.
                        </p>

                        <p className='mt-3'><strong>3 DAYS COOLING OFF/LOOK-UP PERIOD:</strong></p>
                        <p>SUPERLINE FININVEST PRIVATE LIMITED offers a 3-day cooling-off/look-up
                            period during which borrowers will not charge any penalty for prepayment
                            of the loan</p>
                        <p>This policy reflects our commitment to ensuring a transparent and
                            customer-friendly approach to handling cancellation, refund, and
                            fraudulent transaction-related matters</p>


                    </div>
                </div>
            </div>
            <Footer2 />
        </>
    )
}
