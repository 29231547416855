import React, { useState,useEffect } from 'react'
import { AnimationOnScroll } from "react-animation-on-scroll";
export default function EmicalculatorNew() {
  const [LoanTermTab, setLoanTermTab] = useState('month');
  const [emiData, setEmiData] = useState({
    LoanAmount: '5000',
    LoanTerm: '1',
    InterestRate: '10',
    monthlyInstallment:'',
    totalAmount:'',
  })

  useEffect(() => {
    const newEMI = calculateEMI(emiData.LoanAmount, emiData.LoanTerm, emiData.InterestRate);
    const totalAmount = calculateTotalAmount(newEMI, emiData.LoanTerm);
    setEmiData((prevState) => ({
      ...prevState,
      monthlyInstallment: newEMI,
      totalAmount: totalAmount.toFixed(2)
    }));
  }, [LoanTermTab]);


  const handlechange = (e) => {
    const {name, value} = e.target;
    const updatedEmiData = {
      ...emiData,
      [name]: value,
    };
    const newEMI = calculateEMI(updatedEmiData.LoanAmount, updatedEmiData.LoanTerm, updatedEmiData.InterestRate);
    const totalAmount = calculateTotalAmount(newEMI, updatedEmiData.LoanTerm);
    setEmiData({
      ...updatedEmiData,
      monthlyInstallment: newEMI,
      totalAmount: totalAmount.toFixed(2)
    });
  }

  const calculateEMI = (loanAmount, loanTerm, interestRate) => {
    const principal = parseFloat(loanAmount);
    let term = parseFloat(loanTerm);
    if (LoanTermTab === 'year') {
      term = term * 12; 
    }
    const rateOfInterest = parseFloat(interestRate) / 12 / 100; 
    const emi = (principal * rateOfInterest * Math.pow(1 + rateOfInterest, term)) / (Math.pow(1 + rateOfInterest, term) - 1);
    return emi.toFixed(2); 
  };

  const calculateTotalAmount = (emi, loanTerm) => {
    let term = parseFloat(loanTerm);
    if (LoanTermTab === 'year') {
      term = term * 12;
    }
    return (emi * term);
  };


  return (
    <>
      <div className="calculator-new pt-5 pb-5" id="EC">
        <div className="container">
          <div className="row" style={{ marginBottom: '50px' }}>
            <div className="col">
              <div className="heading text-center" >
                <AnimationOnScroll animateIn="animate__zoomIn">
                  {" "}
                  <h3 className='fw-bold'>EMI CALCULATOR</h3>

                </AnimationOnScroll>
              </div>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 mt-3">
              <div className="inputgrp">
                <label htmlFor="LoanAmount" className='input-label'>Loan Amount</label>
                <div className="inputsubgrp">
                  <span>₹</span>&nbsp;
                  <input type="number" min="5000" max="200000" name="LoanAmount" id="LoanAmount" className='input-tag' value={emiData.LoanAmount} onChange={(e) => handlechange(e)} />
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 mt-3">
              <div className="inputgrp">
                <label htmlFor="LoanTerm" className='input-label'>Loan Term</label>
                <div className="inputsubgrp">
                  <input type="number" min="1" name="LoanTerm" id="LoanTerm" className='input-tag' value={emiData.LoanTerm} onChange={(e) => handlechange(e)} />


                  <div className={`tab1 ${LoanTermTab === 'month' ? 'active' : ''}`} onClick={() => setLoanTermTab('month')}>
                    <h5>Months</h5>
                  </div>
                  <div className={`tab1 ${LoanTermTab === 'year' ? 'active' : ''}`} onClick={() => setLoanTermTab('year')}>
                    <h5>Year</h5>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 mt-3">
              <div className="inputgrp">
                <label htmlFor="InterestRate" className='input-label'>Interest Rate</label>
                <div className="inputsubgrp">
                  <input type="number" min="10" name="InterestRate" id="InterestRate" className='input-tag' value={emiData.InterestRate} onChange={(e) => handlechange(e)} />
                  &nbsp;<span>%</span>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 mt-3">
              <div className="amount-box">
                <h6>{emiData?.monthlyInstallment !== NaN && emiData?.monthlyInstallment !==undefined && emiData?.monthlyInstallment !== '' ? emiData?.monthlyInstallment : 0} <span>/ monthly installment</span></h6>
                <h6>{emiData?.LoanAmount !== NaN && emiData?.LoanAmount !==undefined && emiData?.LoanAmount !== '' ? emiData?.LoanAmount : 0} <span>Principal Amount</span></h6>
                <hr className='hrtag' />
                <h6>{emiData?.totalAmount !== NaN && emiData?.totalAmount !==undefined && emiData?.totalAmount !== '' ? emiData?.totalAmount : 0} <span>Total Amount</span></h6>
                <div className='btns'>
                  <a href="https://play.google.com/store/apps/details?id=com.borrowBuddy" target="_blank" className="ApplyBtns">Apply Now</a>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  )
}
