import React, { useEffect, useState } from 'react'
import Slider from '@mui/material/Slider';
import { AnimationOnScroll } from "react-animation-on-scroll";
export default function EmiCalculator2() {
    const [value, setValue] = React.useState(10000);
    const [value2, setValue2] = React.useState(12);
    const [value3, setValue3] = React.useState(3);

    let widths = window.innerWidth;

    const handleSliderChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleInputChange = (event) => {
        setValue(event.target.value === '' ? 0 : Number(event.target.value));
    };
    const handleSliderChange2 = (event, newValue) => {
        setValue2(newValue);
    };

    const handleInputChange2 = (event) => {
        setValue2(event.target.value === '' ? 0 : Number(event.target.value));
    };
    const handleSliderChange3 = (event, newValue) => {
        setValue3(newValue);
    };

    const handleInputChange3 = (event) => {
        setValue3(event.target.value === '' ? 0 : Number(event.target.value));
    };
    let totalInterest = (value * (1 + value2 * (value3 / 12))) / 200;
    let TotalAmount = value + totalInterest;
    let Emi = TotalAmount / value3;
    return (
        <>
            <div className="calculator pt-5 pb-5" id="EC" >
                <div className="container">
                    <div className="row" style={{ marginBottom: '50px' }}>
                        <div className="col">
                            <div className="heading text-center" >
                                <AnimationOnScroll animateIn="animate__zoomIn">
                                    {" "}
                                    <h3 style={{ color: 'black', fontSize: '35px' }} className='fw-bold'>EMI CALCULATOR</h3>

                                </AnimationOnScroll>
                            </div>
                        </div>
                    </div>
                    <div className={`row py-2 ${(widths >= '1440') ? 'row px-5' : (widths >= '1024') ? 'row px-4' : (widths >= '768') ? 'row px-3' : (widths < '768') ? 'row px-2' : ''}`} id="rowsfirst" style={{ backgroundColor: 'white' }}>
                        <div className="col-lg-7 col-xs-12">
                            <div className="sliders">
                                <div className="LoanAmount pt-5">
                                    <div className="row">
                                        <div className="col">
                                            <h5 style={{ paddingTop: '14px', fontWeight: '600', fontSize: '16px' }}>Loan Amount</h5>
                                        </div>
                                        <div className="col">
                                            <div class="input-group mb-3 " style={{
                                                float: 'right', maxWidth: '127px', height: '50px', borderRadius: '8px', borderRadius: "3px"
                                            }}>
                                                <input type="text" class="form-control" aria-describedby="basic-addon2" value={"₹" + " " + value} onChange={handleInputChange} disabled style={{ color: '#003F75', fontWeight: '700', fontSize: '20px', backgroundColor: 'E3EEF6', border: 'none' }}
                                                />

                                            </div>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col">
                                            <Slider
                                                value={typeof value === 'number' ? value : 0}
                                                onChange={handleSliderChange}
                                                aria-labelledby="input-slider"
                                                min={10000}
                                                max={500000}
                                                step={10000}
                                                style={{ borderRadius: '12px', height: '10px' }}
                                            />
                                        </div>

                                    </div>

                                    <div className="row mt-1">
                                        <div className="col">
                                            <h5 style={{ opacity: '.5', fontSize: '15px' }}>10K</h5>
                                        </div>
                                        <div className="col">
                                            <h5 style={{ float: 'right', opacity: '.5', fontSize: '15px' }}>5L</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="InterestRate pt-3">
                                    <div className="row">
                                        <div className="col">
                                            <h5 style={{ paddingTop: '14px', fontWeight: '600', fontSize: '16px' }}>Interest Rate</h5>
                                        </div>
                                        <div className="col">
                                        
                                            <div class="input-group mb-3 " style={{ float: 'right', maxWidth: '127px', height: '50px', borderRadius: '8px' }}>
                                                <input type="text" class="form-control" aria-describedby="basic-addon2" value={value2 + " " + "%"} onChange={handleInputChange2} disabled style={{ color: '#003F75', fontWeight: '700', fontSize: '20px', backgroundColor: 'E3EEF6', border: 'none' }}
                                                />

                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col">
                                            <Slider
                                                value={typeof value2 === 'number' ? value2 : 0}
                                                onChange={handleSliderChange2}
                                                aria-labelledby="input-slider"
                                                min={12}
                                                max={30}
                                                step={1}
                                                style={{ borderRadius: '12px', height: '10px' }}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-1">
                                        <div className="col">
                                            <h5 style={{ opacity: '.5', fontSize: '15px' }}>12%</h5>
                                        </div>
                                        <div className="col">
                                            <h5 style={{ float: 'right', opacity: '.5', fontSize: '15px' }}>30%</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="SelectEmiOption pt-3">
                                    <div className="row">
                                        <div className="col">
                                            <h5 style={{ paddingTop: '14px', fontWeight: '600', fontSize: '16px' }}>Select EMI Options</h5>
                                        </div>
                                        <div className="col">
                                          
                                            <div class="input-group mb-3 " style={{ float: 'right', maxWidth: '127px', height: '50px', borderRadius: '8px' }}>
                                                <input type="text" class="form-control" aria-describedby="basic-addon2" value={value3 + " " + "Months"} onChange={handleInputChange3} disabled style={{ color: '#003F75', fontWeight: '700', fontSize: '20px', backgroundColor: 'E3EEF6', border: 'none' }}
                                                />

                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col">
                                            <Slider
                                                value={typeof value3 === 'number' ? value3 : 0}
                                                onChange={handleSliderChange3}
                                                aria-labelledby="input-slider"
                                                min={3}
                                                max={36}
                                                step={3}
                                                style={{ borderRadius: '12px', height: '10px' }}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-1">
                                        <div className="col">
                                            <h5 style={{ opacity: '.5', fontSize: '15px' }}>3 Months</h5>
                                        </div>
                                        <div className="col">
                                            <h5 style={{ float: 'right', opacity: '.5', fontSize: '15px' }}>36 Months</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 col-xs-12 mt-5" >
                            <div className="emi" style={{ borderRadius: '.25rem', border: "1px solid rgba(0,0,0,.125)" }}>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="emivalue" style={{
                                            textAlign: 'center', paddingTop: '4rem', borderBottom: '1px solid rgba(0,0,0,.125)', paddingBottom: '2rem', backgroundColor: '#003F75'

                                        }}>
                                            <h5 style={{ fontWeight: '500', fontSize: '20px', color: 'white' }}>Your Monthly Installment:</h5>
                                            <h3 style={{ color: 'white' }}>₹ {Emi.toFixed(2)}</h3>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="calculations" style={{ paddingLeft: '15px', paddingRight: '15px', paddingTop: "1.5rem" }}>

                                            <div className="row mt-3">
                                                <div className="col">
                                                    <h5 style={{ fontWeight: '400', fontSize: '15px' }}>Total Interest</h5>
                                                </div>
                                                <div className="col">
                                                    <h5 style={{ float: 'right', fontWeight: '700' }}>{totalInterest}</h5>
                                                </div>
                                            </div>
                                            <div className="row mt-3">
                                                <div className="col">
                                                    <h5 style={{ fontWeight: '00', fontSize: '15px' }}>Principal Amount</h5>
                                                </div>
                                                <div className="col">
                                                    <h5 style={{ float: 'right', fontWeight: '700' }}>{value}</h5>
                                                </div>
                                            </div>

                                            <hr style={{ borderStyle: 'dashed', borderWidth: '1px', color: 'rgba(0,0,0,.425)' }} />

                                            <div className="row mt-4">
                                                <div className="col">
                                                    <h5 className='h5' style={{ fontWeight: '700' }}>Total Amount</h5>
                                                </div>
                                                <div className="col">
                                                    <h5 className='h5' style={{ float: 'right', fontWeight: '700', color: '#4285F4' }}>{TotalAmount}</h5>
                                                </div>
                                            </div>

                                            <div className="row mt-4 mb-4 px-1">
                                                
                                                {/* <button className="buttonapply" style={{ textAlign: 'center', color: 'white', borderRadius: '10px', fontWeight: '700', border: '0', padding: '11px 26px 11px 26px', background: "linear-gradient(135deg, rgba(1, 185, 245, 1) 0%, rgba(0, 63, 117, 1) 40.7%)" }}>Apply Now</button> */}
                                                <a href="https://play.google.com/store/apps/details?id=com.borrowBuddy" target="_blank" className="buttonapply" style={{ textAlign: 'center', color: 'white', borderRadius: '10px', fontWeight: '700', border: '0', padding: '11px 26px 11px 26px', background: "linear-gradient(135deg, rgba(1, 185, 245, 1) 0%, rgba(0, 63, 117, 1) 40.7%)",textDecoration:'none' }}>Apply Now</a>
                                              
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
