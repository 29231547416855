import { Link, NavLink, useNavigate } from 'react-router-dom'
import darkLogo from '../../assets/Borrowbuddy-logo.png'
import { useEffect, useRef, useState,useLayoutEffect } from 'react'
import { IoIosArrowDown } from "react-icons/io";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import { FaArrowRightLong } from "react-icons/fa6";
import { TbMoneybag } from "react-icons/tb";
function Navbar2() {

    let navRef = useRef(null);

    let dropdownRef = useRef(null);
    const navigate = useNavigate();
    const [screenSize, setScreenSize] = useState('small');
    const [showDropdown, setshowDropdown] = useState(false);
    useLayoutEffect(()=>{
        let width = window.screen.width;
        if (width <= 991) {
            setScreenSize('small');
        } else {
            setScreenSize('large');
        }
    },[])
    useEffect(() => {

        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setshowDropdown(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [])

    function navClickHandler() {
        if (screenSize === 'small') {
            if (navRef) {
                navRef.current.classList.toggle("opened")
            }
        }
    }
    const handleLinkClick = (sectionId) => {

        navigate('/');


        setTimeout(() => {
            
            window.scrollToSection(sectionId);
           
        }, 300);
    };

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handleDropdown = () => {
        setshowDropdown(!showDropdown);
    }

    const handleNavigate =(path)=>{
        setshowDropdown(false);

navigate(path)

    }


    return (
        <>
            <nav ref={navRef} className="navbar navbar-light navbar-expand-lg fixed-top customnavbar">
                <div className="container">
                    <Link className="navbar-brand me-5" href="#" to={'/'} onClick={scrollToTop}>
                        <img src={darkLogo} alt="Logo" />
                    </Link>
                    <button onClick={navClickHandler} className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">

                            <li className="nav-item">
                                <Link className="nav-link" to="/" onClick={() => handleLinkClick('AU')}>About Us</Link>
                            </li>
                            {/* <li className="nav-item">
                                <Link className="nav-link" to="/" onClick={() => handleLinkClick('WWO')}>What we Offer</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/" onClick={() => handleLinkClick('LS')}>How to Apply</Link>
                            </li> */}
                             {
                                screenSize == 'large' ?
                            <li className="nav-item">
                                {/* <Link className="nav-link" href="#" onClick={() => handleLinkClick('ourProduct')}>Our Product</Link> */}
                                <div className='product-navbar-btn' ref={dropdownRef}>
                                    <Link className="nav-link" onClick={() => handleDropdown()} onMouseEnter={() => setshowDropdown(true)}>Our Product <IoIosArrowDown /></Link>
                                    {
                                        showDropdown ?
                                            <div className="dropdown-box">
                                                <div className="box1" style={{ borderTopLeftRadius: '8px', borderTopRightRadius: '8px' }} onClick={() => handleNavigate('/home/Products/InvoiceFinancing')}>
                                                    <div className="imgss">
                                                        <LiaFileInvoiceDollarSolid />
                                                    </div>
                                                    <div className="text">
                                                        <h5>Invoice Financing <FaArrowRightLong /></h5>
                                                        <p>Keep your operations smooth and worry-free with our efficient financing options.</p>
                                                    </div>
                                                </div>
                                                <div className="box1" style={{ borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px' }} onClick={() => handleNavigate('/home/Products/shortTermLoans')}>
                                                    <div className="imgss">
                                                        <TbMoneybag />
                                                    </div>
                                                    <div className="text">
                                                        <h5>Short term loans <FaArrowRightLong /></h5>
                                                        <p>Keep your operations smooth and worry-free with our efficient financing options.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            : ''}
                                </div>
                            </li>
                            :
                            <li className="nav-item">
                                        <div className="dropdown-box-small">
                                            <div className="accordion faqaccordion" id="accordionExample">
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne" >
                                                        Our Product
                                                        </button>
                                                    </h2>
                                                    <div id="collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                        <div className="accordion-body">
                                                           <h5 onClick={() => handleNavigate('/home/Products/InvoiceFinancing')}>Invoice Financing</h5>

                                                           <h5 onClick={() => handleNavigate('/home/Products/shortTermLoans')}>Short term loans</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                            }
                            <li className="nav-item">
                                <Link className="nav-link" to="/" onClick={() => handleLinkClick('EC')}>Calculator</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/" onClick={() => handleLinkClick('FAQ')}>FAQ</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/" onClick={() => handleLinkClick('contact')}>Contact Us</Link>
                            </li>


                        </ul>
                        {/* <button className='btn1 me-2' onClick={() => handleLinkClick('GetApp')}>Get App</button> */}
                        <a href="https://play.google.com/store/apps/details?id=com.borrowBuddy" target="_blank" className="btn1 me-2" style={{textDecoration:'none'}}>Get App</a>
                        {/* <button className='btn2'>Sign Up</button> */}

                    </div>
                </div>

            </nav>

        </>
    )
}

export default Navbar2