import react, { useEffect, useState } from 'react'
import '../css/Loansteps.css';
import LS1 from '../assets/LS-1.png'
import LS11 from '../assets/LS-1-1.png'
import LS2 from '../assets/LS-2.png'
import LS21 from '../assets/LS-2-1.png'
import LS3 from '../assets/LS-3.png'
import LS31 from '../assets/LS-3-1.png'
import LS4 from '../assets/LS-4.png'
import LS41 from '../assets/LS-4-1.png'
import LS5 from '../assets/LS-5.png'
import LS6 from '../assets/LS-6.png'
import LS7 from '../assets/LS-7.png'
import LS8 from '../assets/LS-8.png'


import { AnimationOnScroll } from "react-animation-on-scroll";
export default function LoanSteps() {
    const [activeStep, setActiveStep] = useState(1);

    useEffect(() => {
        const textElements = document.querySelectorAll('.text1, .text2, .text3, .text4');
        const circleElements = document.querySelectorAll('.circle');

        const handleScroll = () => {
            textElements.forEach((textElement, index) => {
                const rect = textElement.getBoundingClientRect();
                if (rect.top >= 0 && rect.bottom <= (window.innerHeight * 1)) {
                    setActiveStep(index + 1);
                    console.log(activeStep);
                }
            });
        };



        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <>
            <div className="Loan-steps-third pb-5" id="LS">
                <div className="container">
                    <div className="row pb-4">
                        <div className="col">
                            <div className="heading text-center">
                                <AnimationOnScroll animateIn="animate__zoomIn">
                                    {" "}
                                    <h3  className='fw-bold'>LOAN STEPS</h3>
                                </AnimationOnScroll>
                            </div>
                        </div>
                    </div>


                    <div className="row pt-4">
                        <div className="col-6 text-center pe-0 pt-0">
                            
                            <div className={`imagess  ${(activeStep === 1) ? '' : 'd-none'}`}>
                                <div className="images me-3">
                                    <img src={LS5} width={'680px'} alt="" />
                                </div>
                            </div>
                           
                            <div className={`imagess  ${(activeStep === 2) ? '' : 'd-none'}`} >
                                <div className="images me-3">
                                    <img src={LS6} width={'650px'} alt="" />
                                </div>
                            </div>
                            
                            <div className={`imagess   ${(activeStep === 3) ? '' : 'd-none'}`}>
                                <div className="images me-3">
                                    <img src={LS7} width={'550px'} alt="" />
                                </div>
                            </div>
                           
                            <div className={`imagess   ${(activeStep === 4) ? '' : 'd-none'}`} >
                                <div className="images me-3">
                                    <img src={LS8} width={'650px'} alt="" />
                                </div>
                            </div>
                          



                        </div>
                        <div className="col-6 ps-0">
                            <div className="texts">
                                <div className={`text1 ${(activeStep === 1) ? 'activetext' : ''}`}>
                                    <div className={`box ${(activeStep === 1) ? 'activebox' : ''}`}>
                                       
                                        <h6>1. Submit Loan Application with neccessary documents</h6>
                                        <h6 className="subheading">Begin by submitting your loan application along with the necessary documents. Our user-friendly app will guide you through this step, ensuring a hassle-free experience.
                                        </h6>
                                    </div>
                                </div>
                                <div className={`text2 ${(activeStep === 2) ? 'activetext' : ''}`} >
                                    <div className={`box ${(activeStep === 2) ? 'activebox' : ''}`}>
                                        
                                        <h6>2. Get Instant - in principal approval based on details</h6>
                                        <h6 className="subheading">Get instant in-principal approval based on the details you provide. No more waiting for days; know within moments if you're eligible for a loan with Borrow Buddy.
                                        </h6>
                                    </div>
                                </div>
                                <div className={`text3 ${(activeStep === 3) ? 'activetext' : ''}`}>
                                    <div className={`box ${(activeStep === 3) ? 'activebox' : ''}`} >
                                        
                                        <h6>3.Complete Video KYC and e-sign Process</h6>
                                        <h6 className="subheading">For added security and convenience, complete our video KYC (Know Your Customer) and e-sign process. It's a quick and secure way to verify your identity and complete the loan application.</h6>
                                    </div>
                                </div>
                                <div className={`text4 ${(activeStep === 4) ? 'activetext' : ''}`} >
                                    <div className={`box ${(activeStep === 4) ? 'activebox' : ''}`} >
                                        
                                        <h6>4. Successfull Loan disbursement in your bank account</h6>
                                        <h6 className="subheading">Once your loan is approved, the funds are successfully disbursed into your bank account. Access them instantly to cover your financial needs. Borrow Buddy makes it simple and quick.</h6>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>





                </div>
            </div>


            <div className="Loan-steps-fourth pt-5 pb-5 d-none " id="LS">
                <div className="container">
                    <div className="row pb-4">
                        <div className="col">
                            <div className="heading text-center">
                                <h3  className='fw-bold'>LOAN STEPS</h3>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-5">
                        <div className="col-12">
                            <div className="rowfirst">
                                <h3 > Submit Loan Application with neccessary documents</h3>

                            </div>
                        </div>
                        <div className="col-12">
                            <div className="rowfirstimg">
                                <img src={LS5} alt="" width={'50%'} />
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-12">
                            <div className="rowfirst">
                                <h3 > Get Instant - in principal approval based on details</h3>

                            </div>
                        </div>
                        <div className="col-12">
                            <div className="rowfirstimg">
                                <img src={LS6} alt="" width={'40%'} />
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-12">
                            <div className="rowfirst">
                                <h3>Complete Video KYC and e-sign Process</h3>

                            </div>
                        </div>
                        <div className="col-12">
                            <div className="rowfirstimg">
                                <img src={LS7} alt="" width={'40%'} />
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-12">
                            <div className="rowfirst">
                                <h3 > Successfull Loan disbursement in your bank account</h3>

                            </div>
                        </div>
                        <div className="col-12">
                            <div className="rowfirstimg">
                                <img src={LS8} alt="" width={'40%'} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}
