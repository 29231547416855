import React from 'react'
import Navbar2 from '../component/Navbar/navbar2';
import Footer2 from '../component/Footer/Footer2';
export default function PrivacyPolicy() {
    return (
        <>
            <Navbar2 />
            <div className="privacypolicy mb-5">
                <div className="container">
                    <div className="row">
                        <h2>Privacy Policy</h2>
                        <p>The company BorrowBuddy E-Service Private Limited, also known as
                            'Company' or 'We,' is a technology company whose goal is to create and
                            facilitate financial goods and services for our clients
                            ('Customer/You/Your/User'). Across all of our assets, including the
                            website, mobile applications, and social media platforms, we place a high
                            priority on the privacy, rights, confidentiality, and security of personal
                            information shared with or collected by the Company.
                        </p>
                        <p>This Privacy and Data Security Policy, often known as the "Privacy Policy,"
                            demonstrates our dedication to a secure, open, and compliant
                            architecture. This paper describes the methods by which BorrowBuddy
                            uses its website, several mobile applications, and social media platforms
                            to gather, store, and handle customer-specific data</p>

                        <p>"BorrowBuddy Platform" refers to all of the above stated resources. This
                            Privacy Policy applies to all users, both current and former. Users
                            explicitly agree to accept this Privacy Policy by visiting, registering for,
                            accessing, or using any of our offerings from the BorrowBuddy Platform,
                            including goods, services, content, features, technologies, and
                            functionalities. Any feature or procedure pertaining to customer
                            interaction is governed by this policy unless the company specifically
                            states otherwise."
                        </p>

                        <h3>Policy details and applicability</h3>

                        <p>
                            Personal information of people or groups, including any data, documents,
                            or details pertaining to a natural person or group, is covered by this privacy
                            policy. Such data may be used to identify an individual or group, directly or
                            indirectly, or in conjunction with other information that BorrowBuddy has
                            access to
                        </p>
                        <p>Unless otherwise indicated, this privacy statement's rules apply to all
                            BorrowBuddy services, whether used for personal or business purposes.
                            The firm maintains the right, at its sole discretion, to alter this privacy
                            policy and post it on the BorrowBuddy Platform. It is highly recommended
                            that users read the policy before using any BorrowBuddy platforms, and
                            then again from time to time after that. There can be specific warnings
                            sent out for big changes. The Company does not accept responsibility for
                            any misinterpretations that arise from a user's disregard for the current
                            Privacy Policy.</p>

                        <h3>Information Collected:</h3>
                        <p>Providing a seamless user experience, we may collect any or all of the
                            following personal information</p>

                        <h3>Device Information:</h3>
                        <p>Device Information Collection: If you use a laptop, smartphone, tablet, or
                            other device to access the BorrowBuddy platform, we could gather
                            information from those devices as well. IP addresses, browsing history,
                            device IDs or unique identifiers, device types, geolocation data, computer
                            and internet specifics, mobile network data, page view statistics, referral
                            URLs, advertisement data, web log data, and comparable information are
                            all included in this. We could also use cookies and web beacons to collect
                            anonymous data. We may gather and keep the information you provide us
                            or agree to share with us when you engage with BorrowBuddy Services via
                            web forms, account updates, social media exchanges,
                            community/BorrowBuddy online chats, or any other channel.
                        </p>



                        <h3>Report on credit information :</h3>
                        <p>We may obtain your credit information, including your credit score and
                            credit information report, from Credit Information Companies (CICs) with
                            your consent and in accordance with the current regulatory framework for
                            accessing such information." If you specifically name BorrowBuddy as your
                            legally recognized and willing agent or representative to obtain your credit
                            data from CICs, BorrowBuddy will only have access to this data. You
                            understand that BorrowBuddy and CICs rely on the consent and authority
                            you have given to BorrowBuddy by allowing BorrowBuddy to use and
                            obtain your credit information report</p>


                        <h3>Other information</h3>
                        <p>We may gather a variety of data about your transactions and activities
                            when using the BorrowBuddy platform, including but not limited to:</p>

                        <p className='ps-3'>- Contact Details: Name, address, phone number, email, and any pertinent
                            information.
                        </p>
                        <p className='ps-3'>- Personal Identifying Information: National ID number or birthdate.
                        </p>
                        <p className='ps-3'>- Documentation: proof of address, legally valid documents, evidence of
                            income, PAN, credit report, credit score, etc</p>
                        <p className='ps-3'>- Financial Data: Complete bank account numbers supplied during the
                            BorrowBuddy Services or associated with your BorrowBuddy account.</p>
                        <p>Access to the user's SMS inbox through the BorrowBuddy platform, with
                            express authorization from the user, permits the gathering of relevant data
                            from text messages sent by service and product suppliers. This improves
                            access to financial services and products, helps with spending
                            management, and offers information. We rigorously adhere to user privacy
                            while accessing business correspondence</p>

                        <h3>Consent
                        </h3>

                        <p>By utilising any BorrowBuddy platform, you thus expressly consent to the
                            collection, use, and dissemination of the aforementioned facts, documents,
                            and information by BorrowBuddy, its marketing channels, business
                            partners, and agents for the reasons set out in this Privacy Policy.
                        </p>


                        <p>Additionally, you thus specifically consent to receiving communications
                            from BorrowBuddy, its partners, business associates, and representatives
                            by email, SMS, calls, WhatsApp, and other appropriate methods. This
                            correspondence could consist of service updates, product knowledge
                            exchanges, promotional offers via the BorrowBuddy Platform, and
                            bargains from its business partners.
                        </p>
                        <p>You provide express consent for Third parties—such as banks, credit
                            reporting agencies, governments, or any other legally mandated
                            organisation—to get your personal information in order to perform the
                            services you have requested.
                        </p>

                        <h3>Uses and objectives of data collection</h3>
                        <p>The major goal of the Company's collection of any or all of the
                            aforementioned personal information is to give you a safe, easy,
                            effective, clever, and individualised experience while using
                            Borrowbuddy products or goods and services that Borrowbuddy
                            facilitates through its partners</p>
                        {/* <h3>We may use your personal information to:</h3>
                        <ul>
                            <li>Your personal information may be used by us to: Give you access to
                                customer support and Borrowbuddy services; Manage transactions
                                and send you alerts about them; and Verify your identity when
                                setting up an account and changing your password.
                            </li>
                            <li>Organize and protect our IT infrastructure; send out reminders for
                                service updates; target marketing and advertising; and make
                                promotional offers based on your communication preferences. may
                                get in contact with you at any number, by voice call, SMS, email,
                                WhatsApp, or automated dialing; Verify the information's accuracy,
                                check for solvency and creditworthiness, and get confirmation from
                                the relevant parties.
                            </li>
                            <li>Control risk, or identify, stop, and/or correct fraud or other potentially
                                unlawful or forbidden activity; identify, stop, or correct policy or
                                relevant user agreement breaches; enhance the Borrowbuddy
                                Services by personalising your usage; evaluate the effectiveness of
                                the Borrowbuddy Services and make layout and content
                                improvements;</li>

                        </ul> */}
                        <h3>COLLECTION OF PERSONAL & SENSITIVE DATA</h3>
                        <p>The data collected by the app and its utilization depend on your management of privacy controls on your device. Upon installing the app, we securely store the gathered information, associating it with unique identifiers linked to your specific device.</p>
                        <p><i>We explicitly seek permissions from you during the app installation process to obtain necessary information from your device. This includes the unique ID (IMEI number), details about the operating system, SDK version, mobile network information (carrier name, SIM Serial, SIM Slot), your profile information, a list of installed apps, and Wi-Fi information.</i></p>
                        <p>The collected device information serves multiple purposes, such as facilitating automatic updates and enhancing security measures to prevent unauthorized access to your account from other devices. Additionally, this data offers valuable insights into your identity as a device holder and the behavior of your device. This insight enables us to improve our services continually, providing you with an enhanced, customized user experience.
                        </p>
                        <p>Further details on the key data collected from each granted permission and how this data is utilized are elaborated below.
                        </p>
                        <div className='table-responsive'>
                            <table className='table '>
                                <tbody>
                                    <tr>
                                        <th>S. NO.</th>
                                        <th>Permission Name</th>
                                        <th>What data is collected</th>
                                        <th>How the data is used</th>
                                    </tr>

                                    <tr>
                                        <td>1.</td>
                                        <td>Camera related permissions</td>
                                        <td>Access to the camera is necessary to facilitate the easy scanning or capture of mandatory KYC documents. This process saves time by enabling automatic filling of pertinent data, ensuring a seamless experience for you while using our application.</td>
                                        <td>We use the data provided by using camera to analyze your creditworthiness, loan eligibility, check KYC documents, and the terms of your loans..</td>
                                    </tr>
                                    <tr>
                                        <td>2.</td>
                                        <td>Storage related Permissions</td>
                                        <td>The authorization for storage is essential to securely download and store your KYC and other pertinent documents on your mobile device. This enables the easy uploading of KYC documents, expediting the completion of your loan application and subsequent disbursement. This measure is implemented to guarantee a smooth and seamless experience for you on our application.

                                            <br />
                                            <i>You will not be able to use the App if you disable this access.</i>
                                        </td>
                                        <td>
                                            We use this permission to securely download and save documents to your mobile device so that they can then be easily uploaded for faster completion of your loan application
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>3.</td>
                                        <td>Location related permission</td>
                                        <td>Location-related permissions are gathered when enabled on your mobile device. This permission is crucial for evaluating the feasibility of your loan application.
                                            <br />
                                            <i>We collect this data when our App is running in the background of your mobile device. You will not be able to use the App if you disable the location services.</i>
                                        </td>
                                        <td>We use this data taken by granting this permission for verifying the address and undertaking a continuous credit risk assessment. It will expedite the completion of your ICC process along with facilitating better credit access to the customers. It will also help in strengthening security against any unauthorized access to your account. This permission will also be used for providing better customer service and providing Customized offers.</td>
                                    </tr>
                                    <tr>
                                        <td>4.</td>
                                        <td>Phone related permissions</td>
                                        <td>We gather and retain information about your hardware model, build model, RAM storage, unique device identifiers such as IMEI, serial number, SSAIO, SIM information (including network operators/roaming state, MNC and MCC codes), and Wi-Fi details (including MAC Address). Additionally, we collect information related to mobile network details.
                                            <br />
                                            <i>You will not be able to use the App if you disable this access.</i>
                                        </td>
                                        <td>The data collected here is used for Research and Development, for enabling Marketing and Outreach, to uniquely identify the devices and to ensure that no unauthorized device acts on your behalf which will help us in preventing frauds. This also helps in enriching your credit profile and assessing your creditworthiness.</td>
                                    </tr>
                                    <tr>
                                        <td>5.</td>
                                        <td>SMS related permissions</td>
                                        <td>When you provide us with access to SMS, we retrieve all SMS data from your mobile device. All data obtained through SMS access is securely stored on Borrowbuddy servers, and we uphold a strict policy of not sharing this information with any Third-party. This data is exclusively utilized for internal assessments.

                                            <br />
                                            <i>This data may be collected even when the app is not in use. You will not be able to use the App if you disable this access.</i>
                                        </td>
                                        <td>The data collected here is used for loan processing and it enables our team to assess credit worthiness, credit risk and detect any fraud in a better manner resulting in speedy disbursal.</td>
                                    </tr>
                                    <tr>
                                        <td>6.</td>
                                        <td>Installed Application related permissions</td>
                                        <td>We gather and retain metadata information for each installed application, encompassing details such as the application name, package name, installation time, update time, version name, and version code on your device.
                                            <br />
                                            <i>You will not be able to use the App if you disable this access.</i>
                                        </td>
                                        <td>We use the data colleced here to assess your creditworthiness and provide you with pre-approved customized loan offers.</td>
                                    </tr>
                                    <tr>
                                        <td>7.</td>
                                        <td>User Personal Information related permissions</td>
                                        <td>We gather and store user account data, comprising the email address and public profile information such as name or photo, contingent upon the platform (e.g., Google) you use to log in to the app.
                                        </td>
                                        <td>This information is required as a part of the registration process and to access our service and is also used to auto-populate relevant fields in the course of our opp interface. Our app also collects your mobile number for verification to check the active SIM status of the device, uniquely identify you, and prevent fraud and unauthorized access.</td>
                                    </tr>
                                    <tr>
                                        <td>8.</td>
                                        <td>Contact Permission</td>
                                        <td>When you authorize Borrowbuddy to access your mobile device's contact book  and store names and contact information from your address book. This facilitates invitations, enables assessment of your phone usage and habits. In your usage of Borrowbuddy Services, we gather all your phonebook contacts, encompassing contact names, phone numbers, account types, and favorites (starred). This data is utilized to discern your social network from your phonebook contacts and identify potentially fraudulent contacts, contributing to fraud detection and risk reduction.
                                        </td>
                                        <td>See, for Enabling the App and Navi Insurance Services; For Issuing Insurance Policies..</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <p>Please note that a few data points collected here are mandatory for building a comprehensive credit risk assessment and credit profile and thus shall be securely shared with our registered service providers, affiliates, and partners. All the data collected with the access of above mentioned permissions are stored to Borrowbuddy servers and is used as a part of assessment.
                        </p>
                        <h3>Other unaffiliated Third parties:</h3>
                        <p><span>We could provide Third parties access to your information for the following
                            purposes:
                        </span></p>
                        <ul>
                            <li><p>Risk Management and Fraud Prevention: Recognizing, assessing,
                                and controlling risks in order to stop fraud.</p>
                                <p className='ps-3'>- Alerting you to any attempted fraud on your accounts.</p>
                                <p className='ps-3'>- Evaluating credit risk for BorrowBuddy Services transactions</p></li>
                            <li>Dispute Resolution and Risk Mitigation: In order to stop fraud and
                                control risk, it is required to provide account information in the event
                                of disputes, claims, chargebacks, or other situations.</li>
                            <li>Customer Service:Helping you take care of your accounts or settling
                                disputes over transactions or invoices.</li>
                            <li>Legal Compliance: Supporting adherence to specifications for
                                counterterrorism financing verification and anti-money laundering.</li>
                            <li>Service Providers: Giving our business operations help from
                                contracted service providers, with the guarantee that they will only
                                use your information as stipulated in our contracts.</li>
                        </ul>

                        <h3>Cookies Policy</h3>
                        <p>On specific pages of the app, we gather data using techniques like
                            "cookies" and similar information sets to analyse the page flow, track
                            the success of promotions, and advance trust and safety. Cookies are
                            little files that are stored on the hard disc or other storage of your
                            device to help us provide our services.</p>

                        <p>How to stop receiving marketing communications from Borrowbuddy:</p>
                        <p>Please be aware that processing your request might take up to 15
                            business days. You expressly waive the Do Not Call (DNC) and Do Not
                            Disturb (DND) registrations on your phone/mobile numbers in order to
                            use the Borrowbuddy Platform and contact you for such purposes. As a
                            result, there won't be a need to monitor the DNC or DND status of any
                            numbers you may have left on our Borrowbuddy Platform. Sending
                            SMSs and/or placing phone calls are two possible ways to get in touch
                            with you.
                        </p>
                        <h3>Data Retention</h3>
                        <p>Your information will be kept by Borrowbuddy for as long as it takes to
                            provide you with the Borrowbuddy Services that are accessible on the
                            Borrowbuddy Platform or until you want to have your Borrowbuddy
                            account terminated, whichever comes first. Additionally, as part of its
                            contractual obligations with the banks and other financial institutions,
                            Borrowbuddy may also be required by law to retain certain
                            information; in such case, such information will be retained for the
                            period of time specified by the applicable law or as part of
                            Borrowbuddy's contractual obligation.
                        </p>
                        <p>Even when your connection is terminated, BorrowBuddy may still utilise
                            your anonymized data, either alone or in conjunction with other users'
                            anonymized data. We utilise this combined anonymised data for
                            research, data analysis, and profiling to learn more about our users and
                            their profiles. We could save your contact data and application
                            information (if any) in order to thwart fraud, bolster legal defences, or
                            provide proof in court.
                        </p>
                        <h3>Advertisement on the application or website
                        </h3>
                        <p>On our application or website, we don't run advertisements</p>

                        <h3>Grievance Redressal</h3>
                        <div className="contactdetails ps-4">
                            <p>Send an email to compliances<a href='mailto:support@borrowbuddy.co.in'>@borrowbuddy.co.in</a> in with any
                                grievance, complaint, questions, comments, concerns, or criticism
                                regarding the handling of your information, our privacy and security
                                policy, or any other privacy or security issue.</p>

                            <p>Name and Contact details of the Grievance Redressal Officer of the
                                Company</p>
                            <p><span>Name:</span>Mr.Abhinav Singla</p>
                            <p><span>Address:</span>M-3 Pratap Bhawan, 5 Bahadur Shah Zafar Marg I.P.Estate
                                New Delhi Central Delhi- 110002, India</p>
                            <p><span>Email:</span><a href='mailto:support@borrowbuddy.co.in'>support@borrowbuddy.co.in</a></p>
                            <p><span>Contact:</span>+91 1166668018</p>
                            {/* <p><span>Contact:</span>971114407011</p> */}
                        </div>

                        <h3>Updating personal information</h3>
                        <p>You can email or contact our customer support team if the personal
                            information you provided to Borrowbuddy needs to be updated. The
                            Borrowbuddy platforms provide the same kind of operation.</p>
                        <h3>Deletion of Information:</h3>
                        <p><span>Send an email requesting the deletion of your account or personal data to
                        support@borrowbuddy.co.in. Once we get it, we will remove your
                            information and stop sending you correspondence. Note that if your
                            information is no longer available, discontinuation may have an impact on
                            services linked to the items you have chosen</span></p>
                        <h3>Change in Privacy Policy</h3>
                        <p><span>Borrowbuddy reserves the right to change its privacy policy from time to
                            time. The same will be updated on the website on the date the changes
                            have been made from.</span></p>


                    </div>
                </div>
            </div>
            <Footer2 />
        </>
    )
}
