import React, { useRef, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import '../css/Caraousel.css';

import { AnimationOnScroll } from "react-animation-on-scroll";
import arrowbtn from '../assets/arrowbtn.png'
import wwo1 from '../assets/wwo-1.png'
import wwo2 from '../assets/wwo-2.png'
import wwo3 from '../assets/wwo-3.png'
import wwo11 from '../assets/wwo1-1.png'
import wwo21 from '../assets/wwo2-2.png'
import wwo31 from '../assets/wwo3-1.png'
import { EffectCoverflow, Pagination, Navigation } from 'swiper/modules';
export default function Caraousel({ data }) {

    const [activeSlideIndex, setActiveSlideIndex] = useState(0);
    const swiperRef = useRef(null);
    const handleSlideChange = (swiper) => {
        setActiveSlideIndex(swiper.realIndex);
    };

    return (
        <>
            <div className="container" i>
                <div className="row text-center">
                    <div className="col">
                        {/* <AnimationOnScroll animateIn="animate__zoomIn">
                            {" "} */}
                            <h4 style={{ color: '#3B3B3B', fontSize: '30px' }}>What we offer</h4>
                        {/* </AnimationOnScroll> */}
                    </div>
                </div>
                <Swiper
                    effect={'coverflow'}
                    grabCursor={true}
                    centeredSlides={true}
                    slidesPerView={'auto'}
                    coverflowEffect={{
                        rotate: 50,
                        stretch: 0,
                        depth: 100,
                        modifier: 1,
                        slideShadows: true,
                    }}

                    onSlideChange={(swiper) => handleSlideChange(swiper)}
                    pagination={{ el: '.swiper-pagination', clickable: true }}
                    Navigation={{
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                        clickable: true,
                    }}

                    modules={[EffectCoverflow, Pagination, Navigation]}
                    className="mySwiper"
                >
                    {
                        data && data?.length > 0 ?
                        data?.map((value,index)=>{
                            return(

                    <SwiperSlide>
                        <div className="card1 text-center">
                            <div className="wrapper">

                                <div className="imgs">
                                    <img src={activeSlideIndex === index ? value?.ActiveImage : value?.Image} alt="" />
                                </div>
                              
                                    <h4 classsName="ch4" style={{fontSize:'30px'}}>{value?.text}</h4>
                                     <p className="cp">{value?.subtext}</p>
                                    
                                
                            </div>

                        </div>
                    </SwiperSlide>
                            )
                        })
                    :''}

                    {/* <SwiperSlide>
                        <div className="card1 text-center">
                            <div className="wrapper">

                                <div className="imgs">
                                    <img src={activeSlideIndex === 0 ? wwo11 : wwo1} alt="" />
                                </div>
                                <h4 classsName="ch4">Hassle-Free Documentation</h4>
                                <p className="cp">Applying for a loan is now easier than ever. We've streamlined the document submission process, making it a breeze. Our user-friendly app will walk you through each step effortlessly.</p>
                            </div>

                        </div>
                    </SwiperSlide>


                    <SwiperSlide>
                        <div className="card2 text-center">
                            <div className="imgs">
                                <img src={activeSlideIndex === 1 ? wwo2 : wwo21} alt="" />
                            </div>
                            <h4 classsName="ch4">Crystal-Clear Pricing</h4>
                            <p className="cp">Transparency is our mantra at Borrow Buddy. Our pricing is straightforward, with no hidden fees. Before you borrow, you'll know exactly what you'll pay and when. Borrow with peace of mind, knowing our terms are as clear as day.</p>

                        </div>
                    </SwiperSlide>


                    <SwiperSlide>
                        <div className="card3 text-center backimg2">
                            <div className="wrapper">

                                <div className="imgs" >
                                    <img src={activeSlideIndex === 2 ? wwo31 : wwo3} alt="" />
                                </div>
                                <h4 classsName="ch4">Customized Loan Solutions</h4>
                                <p className="cp">   We understand that everyone's financial needs are different. That's why Borrow Buddy offers a variety of loan options to match your specific requirements. Whether it's a small loan for unexpected expenses or a larger amount for significant goals, we've got you covered.</p>
                            </div>

                        </div>
                    </SwiperSlide>



                    <SwiperSlide>
                        <div className="card1 text-center">
                            <div className="wrapper">

                                <div className="imgs">
                                    <img src={activeSlideIndex === 3 ? wwo11 : wwo1} alt="" />
                                </div>
                                <h4 classsName="ch4">Borrow What You Need</h4>
                                <p className="cp">With Borrow Buddy, you can borrow any amount from 5,000 to 2 lakhs, depending on your needs and eligibility. Whether it's a modest sum or a more substantial financial boost, we're here to assist you.</p>
                            </div>

                        </div>
                    </SwiperSlide>


                    <SwiperSlide>
                        <div className="card2 text-center">
                            <div className="imgs">
                                <img src={activeSlideIndex === 4 ? wwo2 : wwo21} alt="" />
                            </div>
                            <h4 classsName="ch4">Loan Terms That Suit You</h4>
                            <p className="cp">Choose a loan duration that aligns with your financial goals. With tenures ranging from 60 days to 1 year, you have the flexibility to repay at your preferred pace.</p>

                        </div>
                    </SwiperSlide>


                    <SwiperSlide>
                        <div className="card3 text-center backimg2">
                            <div className="wrapper">

                                <div className="imgs" >
                                    <img src={activeSlideIndex === 5 ? wwo31 : wwo3} alt="" />
                                </div>
                                <h4 classsName="ch4">Payment Tailored to You </h4>
                                <p className="cp">At Borrow Buddy, we put you in control of your finances. Our flexible interest payment cycles allow you to create a repayment plan that aligns with your budget and lifestyle</p>
                            </div>

                        </div>
                    </SwiperSlide> */}




                    <div className="slider-controler">
                        {/* <div className='swiper-button-prev slider-arrow'>
                        <ion-icon name="arrow-back-outline"></ion-icon>
                    </div>
                    <div className='swiper-button-next slider-arrow'>
                        <ion-icon name="arrow-forward-out"></ion-icon>
                    </div> */}
                        <div className="swiper-pagination"></div>
                    </div>
                </Swiper>


                {/* <div className="row mt-5">
                <div className='btns1 text-center'>
                    
                    <img src={arrowbtn} alt=""  />
                </div>
                
            </div> */}
            </div>
        </>
    )
}
