import './App.css';
import { Routes, Route } from 'react-router-dom';

import Homepage from './pages/Homepage';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Support from './pages/Support';
import TermsCondition from './pages/TermsCondition';
import CancellationPolicy from './pages/CancellationPolicy';
import HomePageNew from './pages/HomePageNew';
import Products from './pages/Products';
function App() {
  return (
    <div className="App">
      <Routes>
        {/* <Route index element={<Homepage />}></Route> */}
        <Route index element={<HomePageNew />}></Route>
        <Route path={'/home/PrivacyPolicy'} element={<PrivacyPolicy />}></Route>
        <Route path={'/home/Support'} element={<Support />}></Route>
        <Route path={'/home/TermsCondition'} element={<TermsCondition />}></Route>
        <Route path={'/home/RefundCancellation'} element={<CancellationPolicy />}></Route>
        <Route path={'/home/Products/:product'} element={<Products />}></Route>
      </Routes>
    </div>
  );
}

export default App;
