import '../App.css';
import '../App.scss';
import OurRoutes from './Routes';
import * as Routers from './Routers';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Suspense } from 'react';
function AppRouter() {
    return (
        <Router>
            <Suspense>
                <Routes>
                    <Route path={OurRoutes.Homepage} element={<Routers.Homepage />} />
                    <Route path={OurRoutes.PrivacyPolicy} element={<Routers.PrivacyPolicy />} />
                    <Route path={OurRoutes.TermsCondition} element={<Routers.TermsCondition />} />
                    <Route path={OurRoutes.Support} element={<Routers.Support />} />
                </Routes>
            </Suspense>
        </Router>
    );
}

export default AppRouter;
